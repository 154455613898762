import React, { useState } from "react";
import "./Banner.css";

const Banner: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const closeBanner = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="banner-container">
      <div className="banner-content">
        <h1>Dashboard Update.</h1>
        <p>
          We've upgraded the desk booking experience on your dashboard, easier access to book,
          cancel and change your desk bookings. Visit{" "}
          <a href="https://spo.bhpbilliton.com/sites/globalproperty/SitePages/My-Workday-info-and-support-centre.aspx">
            My Workday Centre
          </a>{" "}
          for details.
        </p>
        <button onClick={closeBanner} className="close-button">
          X
        </button>
      </div>
    </div>
  );
};

export default Banner;
