import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { bhpColor } from "styles/globals";
import { BuildingType, BuildingWithCapacity } from "interfaces/Building";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { useState } from "react";
import { useRootStore } from "providers/RootStoreProvider";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import HourlyBookingRanges from "./HourlyBookingRanges";
import { PopupInformationWidget } from "components/settings/PopupInformationWidget";
import InformationIcon from "components/utils/InformationIcon";
import { mdiInformation } from "@mdi/js";
import Icon from "@mdi/react";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: bhpColor.blueGrey1,
      display: "flex",
      "& .MuiGrid-item": {
        display: "flex",
      },
      "&.padded": {
        padding: "12px",
      },
      borderRadius: "4px",
      alignItems: "center",
    },
    officeLocation: {
      textAlign: "start",
      color: bhpColor.grey1,
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        alignItems: "end",
      },
    },
    detailPanel: {
      display: "flex",
      justifyContent: "center",
    },
    fullPanel: {
      display: "flex",
      justifyContent: "center",
      borderRadius: "4px",
      padding: "8px",
    },
    largeNumber: {
      fontSize: "35px",
      fontWeight: 700,
      color: bhpColor.orange1,
      height: "40px",
      display: "flex",
      alignItems: "center",
    },
    title: {
      fontSize: "14px",
      fontWeight: 700,
      justifyContent: "start",
      textAlign: "start",
      color: bhpColor.blueGrey1,
      paddingTop: "4px",
      paddingBottom: "4px",
      "&.wrap": {
        whiteSpace: "pre-line",
      },
    },
    icon: {
      color: bhpColor.grey1,
    },
  })
);

interface OfficeCapacityProps {
  buildingDetail: BuildingWithCapacity | null;
  date: Date;
  hideName?: boolean;
  fullWidth?: boolean;
  snug?: boolean;
  showHourlyGroupings?: boolean;
  transparent?: boolean;
  t: any;
}

const OfficeCapacityWidget_V2 = observer((props: OfficeCapacityProps) => {
  const { buildingDetail, date, hideName, fullWidth, snug, t, showHourlyGroupings, transparent } =
    props;
  const classes = innerStyles();
  const theme = useTheme();
  const store = useRootStore();

  const range = { red: 89, amber: 69 };
  const [informationOpen, setInformationOpen] = useState(false);

  const handleInformationOpen = () => {
    setInformationOpen(true);
  };
  const handleInformationClose = () => {
    setInformationOpen(false);
  };

  const smAndUp = useMediaQuery(theme.breakpoints.up("xs"));
  const isOffice = buildingDetail && buildingDetail?.building_type === BuildingType.OFFICE;

  const getPercentage = (total: number, amount: number, decimalPlaces: number = 0) => {
    if (total === 0 || amount === 0) return 0;
    const percentage = Math.ceil(100 * (amount / total));
    return percentage;
  };

  const getIndicatorColor = (percentage: number) => {
    return percentage > range.red
      ? bhpColor.availabilityRed
      : percentage > range.amber
      ? bhpColor.availabilityAmber
      : bhpColor.available;
  };

  const buildingCapacity = buildingDetail ? buildingDetail.total_capacity : "--";
  // const plannedAttendance = buildingDetail ? buildingDetail.planned_attendance : "--";
  const bookedPercentage = buildingDetail
    ? getPercentage(buildingDetail.total_capacity || 0, buildingDetail.booked_attendance || 0)
    : 0;
  const indicatorColor = getIndicatorColor(bookedPercentage);

  const drawDot = (diameter: number, indicatorColor: string) => {
    return (
      <div
        className="dot"
        style={{ height: `${diameter}px`, width: `${diameter}px`, backgroundColor: indicatorColor }}
      ></div>
    );
  };

  return (
    <Grid
      container
      className={`${classes.root} ${snug ? "" : "padded"}`}
      style={{
        width: !fullWidth ? "398px" : "100%",
        backgroundColor: transparent ? "transparent" : "white",
      }}
    >
      {!hideName && buildingDetail && (
        <Grid container>
          <Grid className={classes.officeLocation} item xs={7}>
            <Box paddingLeft={1} marginTop={1}>
              <strong>
                {buildingDetail && buildingDetail !== null && buildingDetail.city
                  ? `${buildingDetail.city}, `
                  : ""}
              </strong>
              <div
                style={{
                  display: buildingDetail && buildingDetail.city.length > 8 ? "block" : "inline",
                }}
              >
                {buildingDetail !== null ? `\n${buildingDetail.building_name}` : ""}
              </div>
            </Box>
          </Grid>
          <Grid item xs={5} style={{ display: "flex", justifyContent: "end" }}>
            <Button color="primary" size="small" variant="outlined">
              {t("Desk Availability")}
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        className={classes.fullPanel}
        style={{
          marginTop: hideName ? "" : "12px",
          backgroundColor: transparent ? "transparent" : bhpColor.backgroundGrey,
        }}
      >
        <Grid container>
          <Grid item xs={4} className={classes.detailPanel}>
            <Grid container>
              <Grid item xs={12} className={classes.icon}>
                <LocationOnIcon />
              </Grid>
              <Grid item xs={12} className={`${classes.title} ${fullWidth ? "" : "wrap"}`}>
                {`${t("Bookable Desks")}`}
              </Grid>
              <Grid item xs={12} className={classes.largeNumber}>
                {buildingCapacity}
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid
            item
            xs={4}
            className={classes.detailPanel}
            style={{
              borderLeft: `1px solid ${bhpColor.blueGrey4}`,
              borderRight: `1px solid ${bhpColor.blueGrey4}`,
              paddingLeft: "4px",
              paddingRight: "4px",
            }}
          >
            <Grid container>
              <Grid item xs={12} className={classes.icon}>
                <DateRangeIcon />
              </Grid>
              <Grid item xs={12} className={classes.title}>
                {t("Planned Attendance")}
              </Grid>
              <Grid item xs={12} className={classes.largeNumber}>
                {plannedAttendance}
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs={4} className={classes.detailPanel} style={{ paddingLeft: "4px" }}>
            <Grid container>
              <Grid item xs={12} className={classes.icon}>
                <Grid container>
                  <Grid item xs={6}>
                    <EventSeatIcon />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    onClick={handleInformationOpen}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      color: bhpColor.orange1,
                    }}
                  >
                    <InformationIcon />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.title}>
                {t("Desk Availability Indicator")}
              </Grid>
              <Grid item xs={12} className={classes.largeNumber}>
                {drawDot(35, indicatorColor)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "8px" }}>
          {showHourlyGroupings && (
            <HourlyBookingRanges buildingDetail={buildingDetail} date={date} t={t} />
          )}
        </Grid>
      </Grid>
      <PopupInformationWidget
        t={t}
        isOpen={informationOpen}
        handleClose={handleInformationClose}
        handleSecondaryClick={handleInformationClose}
        title={t("Desk Availability Indicator")}
        content={`<p><span style="color:${bhpColor.available}">Green</span> 0-69% of the total bookable desks</p><p><span style="color:${bhpColor.availabilityAmber}">Amber</span> 70-89%</p><p><span style="color:${bhpColor.availabilityRed}">Red</span> 90->100%</p>`}
        buttonText={"Okay"}
        secondaryButtonText={null}
      />
    </Grid>
  );
});

export default OfficeCapacityWidget_V2;
