import moment from "moment";
import { observer } from "mobx-react-lite";
import { Link, useHistory } from "react-router-dom";
import { Box, Button, useMediaQuery, Tooltip } from "@material-ui/core";
import HeadsetMic from "@material-ui/icons/HeadsetMic";
import EventBusy from "@material-ui/icons/EventBusy";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Schedule, ScheduleType } from "interfaces/Schedule";
import { bhpColor } from "styles/globals";
import { dateInPastArrow, getTimeString, isToday } from "utils/date";
import LoadingZone from "components/schedule/LoadingZone";
import { useBookingStore, useRootStore, useScheduleStore } from "providers/RootStoreProvider";
import { isDayTooFarToBook } from "utils/date";
import { cityAbbr } from "utils/locations";
import { useTranslation } from "react-i18next";
import { mdiMapMarkerRadiusOutline } from "@mdi/js";
import { mdiClockOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { BuildingType } from "interfaces/Building";
import { AxiosError } from "axios";
import { Fragment, useContext, useState } from "react";
import HolidayPopup from "./dialogs/HolidayPopup";
import { HolidayContext } from "Main";
import {
  FavouriteContextMenu,
  getFavouritesInBuilding,
} from "components/booking/FavouriteContextMenu";
import { BasicBooking, DEFAULT_END_TIME, DEFAULT_START_TIME } from "utils/hourlyBookings";
import { getEarliestBooking } from "utils/bookings";
import { toJS } from "mobx";
import { t } from "i18next";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import { ConfirmDialog } from "components/utils/ConfirmDialog";

type ScheduleWidgetProps = {
  schedule?: Schedule;
  showBooking?: boolean;
  handleEditHoursLocation: any;
  upcomingBookings: BasicBooking[] | null;
  showLoading?: any;
  displayMessage?: any;
  isOpen?: any;
  isApiError?: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      justifyContent: "flex-start",
      marginTop: "8px",
      fontSize: "12px",
      height: "28px",
      //fontWeight: "bold",
      textTransform: "none",
      backgroundColor: "transparent",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
      },
    },
    buttonMobile: {
      justifyContent: "center",
    },
    editHoursLocationButton: {
      justifyContent: "center",
      alignSelf: "start",
      fontWeight: "normal",
      marginTop: 0,
      marginBottom: -8,
      marginLeft: -8,
      marginRight: -8,
      width: "112% !important",
      fontSize: 10,
      height: 28,
      textTransform: "none",
      backgroundColor: bhpColor.blueGrey4,
      overflow: "hidden",
      borderRadius: "4px !important",
      [theme.breakpoints.down("sm")]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
      },
    },
    office: {
      border: `2px solid ${bhpColor.blueGrey1}`,
      color: bhpColor.blueGrey1,
    },
    officeSelected: {
      border: `2px solid ${bhpColor.blueGrey1}`,
      color: "white",
      backgroundColor: bhpColor.blueGrey1,
      "&:hover": {
        backgroundColor: bhpColor.blueGrey1,
      },
    },
    remote: {
      border: `2px solid ${bhpColor.blueGrey2}`,
      color: bhpColor.blueGrey2,
      "&:hover": {
        border: `2px solid #496b7c`,
        color: "#496b7c",
      },
    },
    remoteSelected: {
      border: `2px solid ${bhpColor.blueGrey2}`,
      color: "white",
      backgroundColor: bhpColor.blueGrey2,
      "&:hover": {
        backgroundColor: bhpColor.blueGrey2,
      },
    },
    off: {
      border: `2px solid ${bhpColor.grey2}`,
      color: bhpColor.grey2,
      "&:hover": {
        border: `2px solid #496b7c`,
        color: "#496b7c",
      },
    },
    offSelected: {
      border: `2px solid ${bhpColor.grey2}`,
      color: "white",
      backgroundColor: bhpColor.grey2,
      "&:hover": {
        backgroundColor: bhpColor.grey2,
      },
    },
    displayHours: {
      justifyContent: "center",
      textAlign: "center",
      fontSize: 11,
      height: "28px",
      fontWeight: "normal",
      textTransform: "none",
      backgroundColor: "transparent",
      marginTop: 10,
      color: bhpColor.black,
    },
  })
);

const no_desk_image = require("../../assets/no_desk.png");
const OfficeButton = observer((props: any) => {
  const classes = useStyles();
  const store = useRootStore();
  const scheduleStore = useScheduleStore();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const lgAndUp = useMediaQuery(theme.breakpoints.up("lg"));
  const { t, i18n } = useTranslation();
  const getButtonName = (schedule: Schedule, smAndUp: boolean): string => {
    const UNKNOWN = "????";
    if (!showBooking || schedule.status !== ScheduleType.OFFICE) {
      return smAndUp ? t("BHP Location") : "";
    } else {
      if (schedule.space) {
        return `${cityAbbr(schedule.space!.building!)} ${schedule.space?.name}`;
      } else {
        if (props.tooFarAhead) {
          return smAndUp ? t("BHP Location") : "";
        }
        if (schedule.office) {
          return cityAbbr(schedule.office) || UNKNOWN;
        } else {
          return store.userStore.defaultOffice ? cityAbbr(store.userStore.defaultOffice) : UNKNOWN;
        }
      }
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    // Stop event propagation
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    if (!props.tooFarAhead) {
      setAnchorEl(event.currentTarget);
    } else {
      props.onClick();
    }
  };
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [deleteConfirmSchedule, setDeleteConfirmSchedule] = useState<Schedule | null>(null);
  const [bookingId, setBookingId] = useState<number>();
  const drawCancelIcon = (schedule: Schedule, booking_id: number) => {
    return (
      <Link
        to="#"
        onClick={() => {
          setDeleteConfirmDialog(true);
          setDeleteConfirmSchedule(schedule);
          setBookingId(booking_id);
        }}
      >
        <Box
          style={{
            color: "red",
            textDecoration: "unset",
          }}
        >
          <Tooltip title={"Cancel booking"}>
            <CancelIcon
              style={{
                marginTop: "-10px",
                right: smAndUp ? "3px" : "unset",
                position: "absolute",
              }}
            />
          </Tooltip>
        </Box>
      </Link>
    );
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNoDeskNeeded = async () => {
    // const bookDateTimeData = getBookingDateTimeData(deskHrs, btz);
    const from =
      props.schedule.desk_start_time && `${props.schedule.desk_start_time}` !== ""
        ? props.schedule.desk_start_time
        : DEFAULT_START_TIME;

    const to =
      props.schedule.desk_finish_time && `${props.schedule.desk_finish_time}` != ""
        ? props.schedule.desk_finish_time
        : DEFAULT_END_TIME;

    const created_by = "frontend";
    const office_id =
      props.schedule.office && props.schedule.office.id
        ? props.schedule.office.id
        : store.userStore.defaultOffice?.id;
    props.showLoading(true);
    let response = await store.bookingStore.createUserBookingNoDesk(
      store.userStore.me!.id,
      office_id,
      props.schedule.date,
      from,
      props.schedule.date,
      to,
      created_by,
      false
    );

    if (response.status == 201 || response.status == 200) {
      props.showLoading(false);
      props.displayMessage("Successfully Booked");
      props.isOpen(true);
      scheduleStore.schedules.forEach((element, index) => {
        if (element.date === props.schedule.date) {
          element.desk_required = 2;
          element.status = ScheduleType.OFFICE;
        }
      });
    } else {
      props.showLoading(false);
      props.isOpen(true);
      props.isApiError(true);
      props.displayMessage("Booking not saved. Please try again!!");
    }
  };

  const showBooking = props.showBooking;
  const isScheduleOffice = props.schedule.status === ScheduleType.OFFICE;
  const showDesk = showBooking && isScheduleOffice && !props.tooFarAhead;
  const favourites_in_building =
    props.schedule.office && props.schedule.office.id
      ? getFavouritesInBuilding(store.userStore.favouriteSpaces, props.schedule.office.id)
      : [];

  // we show the office icon if the selected day is not in the office (so the button is not selected)
  // or if it is selected but we dont show booking
  const showOfficeIcon = (): boolean => {
    return !isScheduleOffice || (isScheduleOffice && !showBooking) || props.tooFarAhead;
  };
  const bookNowLink = (): boolean => {
    return (
      isScheduleOffice &&
      showBooking &&
      !props.tooFarAhead &&
      props.schedule.office?.building_type === BuildingType.OFFICE
    );
  };

  const openFloorPlan = (id, canEdit, date) => {
    store.buildingStore.setupFloorPlanFromSerraviewId(id, canEdit, date);
  };

  let disable_booking_option = false;
  if (props.schedule.space || props.desk_not_required) {
    disable_booking_option = true;
  }

  return (
    <>
      <ConfirmDialog
        title={t("Change of plan?")}
        primaryButtonText={t("Remove")}
        secondaryButtonText={t("Keep")}
        handleSecondaryClick={() => setDeleteConfirmDialog(false)}
        handlePrimaryClick={async () => {
          if (deleteConfirmSchedule) {
            if (bookingId) {
              store.bookingStore.deleteBooking(bookingId);
              scheduleStore.isDirty = false;
              scheduleStore.isLoading = true;
              scheduleStore.schedules.forEach((element, index) => {
                if (element.date === deleteConfirmSchedule.date) {
                  element.desk_required = 0;
                  element.status = 1;
                }
              });
              scheduleStore.isLoading = false;
            } else await scheduleStore.cancelDesk(deleteConfirmSchedule.date);
          }
          setDeleteConfirmDialog(false);
        }}
        isOpen={deleteConfirmDialog}
      >
        {deleteConfirmSchedule && (
          <p>
            {t("Remove your desk booking for")}{" "}
            <Box fontWeight="bold" color="primary.main" component="span">
              {deleteConfirmSchedule.space?.name}
            </Box>{" "}
            <Box fontWeight="bold" component="span">
              {deleteConfirmSchedule.space?.building?.city}
            </Box>
            {", "}
            <Box component="span">{deleteConfirmSchedule.office?.building_name}</Box>
            {` ${t("on")} `}
            {`${t(moment(deleteConfirmSchedule!.date).format("dddd"))}, ${t(
              moment(deleteConfirmSchedule!.date).format("Do")
            )} ${t(moment(deleteConfirmSchedule!.date).format("MMMM"))}`}
            {`${t("?")} `}
          </p>
        )}
      </ConfirmDialog>
      <Box style={{ display: "flex", whiteSpace: "nowrap", alignItems: "center" }}>
        <Tooltip
          title={
            props.earliestBooking
              ? `${moment(props.earliestBooking.booking_details.start_time_local, [
                  "HH:mm",
                  "H:mm",
                ]).format("hh:mm A")} - ${moment(
                  props.earliestBooking.booking_details.end_time_local,
                  ["HH:mm", "H:mm"]
                ).format("hh:mm A")}`
              : ""
          }
        >
          <Button
            size="small"
            variant="outlined"
            disableElevation
            // onClick={props.onClick}
            style={{
              padding: smAndUp
                ? `${showDesk ? "5px 2px" : ""}`
                : `${theme.spacing(2)}px ${theme.spacing(0.5)}px`,
              fontSize: `${showDesk ? "10px" : ""}`,
              textAlign: "left",
              lineHeight: "1",
              width: "100%",
              fontWeight: `${props.selected ? "bold" : "normal"}`,
            }}
            className={`${classes.button} ${
              props.selected ? classes.officeSelected : classes.office
            } ${!smAndUp ? classes.buttonMobile : ""}`}
            startIcon={
              showOfficeIcon() && smAndUp ? (
                <Icon path={mdiMapMarkerRadiusOutline} size={"20px"} />
              ) : smAndUp ? (
                <></>
              ) : (
                ""
              )
            }
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            {smAndUp ? (
              props.earliestBooking ? (
                <Link
                  to="#"
                  style={{ textDecoration: "none", color: "white" }}
                  onClick={() =>
                    openFloorPlan(
                      props.earliestBooking.space?.serraview_id,
                      false,
                      props.earliestBooking.booking_details.start_date_local
                    )
                  }
                >
                  {cityAbbr(props.earliestBooking.building)}{" "}
                  <span
                    style={{
                      fontSize: lgAndUp ? 11 : 8,
                      color: "#F77F00",
                      textDecoration: "underline",
                    }}
                  >
                    {props.earliestBooking.space?.name}
                  </span>
                </Link>
              ) : (
                getButtonName(props.schedule, smAndUp)
              )
            ) : (
              <Icon path={mdiMapMarkerRadiusOutline} size={"20px"} />
            )}
            {bookNowLink() && !props.schedule.space && !disable_booking_option ? (
              <div
                style={{
                  // cursor: "alias",
                  display: "flex",
                  marginTop: "0px",
                  right: smAndUp ? "12px" : "unset",
                  left: smAndUp ? "unset" : "70px",
                  position: "absolute",
                }}
              >
                {bookNowLink() && favourites_in_building.length > 0 && (
                  <FavouriteContextMenu
                    t={t}
                    building={props.schedule.office}
                    date={props.schedule.date}
                    from={
                      props.schedule.desk_start_time && `${props.schedule.desk_start_time}` !== ""
                        ? props.schedule.desk_start_time
                        : DEFAULT_START_TIME
                    }
                    to={
                      props.schedule.desk_finish_time && `${props.schedule.desk_finish_time}` != ""
                        ? props.schedule.desk_finish_time
                        : DEFAULT_END_TIME
                    }
                    schedule={props.schedule}
                    disableBooking={disable_booking_option}
                  />
                  // </div>
                )}
                <div>
                  <ArrowDropDownOutlinedIcon
                    fontSize="medium"
                    color="primary"
                    style={{
                      // cursor: "alias",
                      display: "flex",
                      marginTop: "3px",
                      right: smAndUp ? "10px" : "unset",
                      left: smAndUp ? "unset" : "70px",
                      // position: "absolute",
                    }}
                  ></ArrowDropDownOutlinedIcon>
                </div>
              </div>
            ) : (
              <div>
                {!props.tooFarAhead && !props.schedule.space ? (
                  <ArrowDropDownOutlinedIcon
                    fontSize="medium"
                    color="primary"
                    style={{
                      // cursor: "alias",
                      display: "flex",
                      marginTop: "-11px",
                      right: smAndUp ? "-5px" : "unset",
                      left: smAndUp ? "unset" : "70px",
                      position: "absolute",
                    }}
                  ></ArrowDropDownOutlinedIcon>
                ) : (
                  ""
                )}
                <div>
                  {!props.tooFarAhead &&
                    props.schedule.space &&
                    props.earliestBooking &&
                    drawCancelIcon(props.schedule, props.earliestBooking.id)}
                </div>
              </div>
            )}
          </Button>
        </Tooltip>
        {!props.schedule.space && (
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            // keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
            onMouseLeave={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={props.onClick}
              disabled={disable_booking_option}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                backgroundColor: props.schedule.space ? bhpColor.orange2 : "",
              }}
              selected={props.schedule.space ? true : false}
            >
              Book a desk
            </MenuItem>
            {bookNowLink() && favourites_in_building.length > 0 && (
              <FavouriteContextMenu
                t={t}
                building={props.schedule.office}
                date={props.schedule.date}
                from={
                  props.schedule.desk_start_time && `${props.schedule.desk_start_time}` !== ""
                    ? props.schedule.desk_start_time
                    : DEFAULT_START_TIME
                }
                to={
                  props.schedule.desk_finish_time && `${props.schedule.desk_finish_time}` != ""
                    ? props.schedule.desk_finish_time
                    : DEFAULT_END_TIME
                }
                schedule={props.schedule}
                menuText={true}
                disableBooking={disable_booking_option}
              />
            )}

            <MenuItem
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                backgroundColor: props.desk_not_required ? bhpColor.orange2 : "",
              }}
              selected={props.desk_not_required ? true : false}
              onClick={handleNoDeskNeeded}
              disabled={disable_booking_option}
            >
              I don't need a desk
            </MenuItem>
          </Menu>
        )}
      </Box>
    </>
  );
});

const OffButton = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Button
      size="small"
      disableElevation
      variant="contained"
      onClick={props.onClick}
      className={`${classes.button} ${props.selected ? classes.offSelected : classes.off} ${
        !smAndUp ? classes.buttonMobile : ""
      }`}
      style={{
        fontWeight: `${props.selected ? "bold" : "normal"}`,
      }}
      startIcon={smAndUp ? <EventBusy style={{ fontSize: 16 }} /> : ""}
    >
      {smAndUp ? t("Off") : <EventBusy style={{ fontSize: 16 }} />}
    </Button>
  );
};

const RemoteButton = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Button
      size="small"
      disableElevation
      variant="outlined"
      onClick={props.onClick}
      className={`${classes.button} ${props.selected ? classes.remoteSelected : classes.remote} ${
        !smAndUp ? classes.buttonMobile : ""
      }`}
      startIcon={smAndUp ? <HeadsetMic style={{ fontSize: 16 }} /> : ""}
      style={{
        fontWeight: `${props.selected ? "bold" : "normal"}`,
      }}
    >
      {smAndUp ? t("Remote") : <HeadsetMic style={{ fontSize: 16 }} />}
    </Button>
  );
};

const DisplayHours = (props: { startTime: any; finishTime: any; scheduleType: ScheduleType }) => {
  const classes = useStyles();
  const { startTime, finishTime, scheduleType } = props;
  return (
    <Box className={classes.displayHours}>
      {startTime && finishTime && scheduleType !== ScheduleType.OFF ? (
        <Box>
          {moment(startTime, ["HH:mm", "H:mm"]).format("hh:mm A")} -{" "}
          {moment(finishTime, ["HH:mm", "H:mm"]).format("hh:mm A")}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

// const getEarliestBooking = (upcomingBookings: BasicBooking[], date: string) => {
//   console.log("Bookings→→→→→→\n", upcomingBookings, "date→→→→\n", date);
//   console.log(
//     "Filtered\n",
//     upcomingBookings.filter((booking) =>
//       moment(new Date(), "hh:mm:ss").isBefore(
//         moment(booking.booking_details?.end_time_local, "hh:mm:ss")
//       )
//     )
//   );
//   if (moment(date).isSame(new Date(), "day")) {
//     return upcomingBookings
//       .filter((booking) =>
//         moment(new Date(), "hh:mm:ss").isBefore(
//           moment(booking.booking_details?.end_time_local, "hh:mm:ss")
//         )
//       )
//       .sort((a, b) => {
//         return Date.parse(a.booking_details?.start_time_local || new Date().toTimeString()) <
//           Date.parse(b.booking_details?.start_time_local || new Date().toTimeString())
//           ? 1
//           : Date.parse(a.booking_details?.start_time_local || new Date().toTimeString()) >
//             Date.parse(b.booking_details?.start_time_local || new Date().toTimeString())
//           ? -1
//           : 0;
//       })[0];
//   } else {
//     return upcomingBookings.slice().sort((a, b) => {
//       return Date.parse(a.booking_details?.start_time_local || new Date().toTimeString()) <
//         Date.parse(b.booking_details?.start_time_local || new Date().toTimeString())
//         ? 1
//         : Date.parse(a.booking_details?.start_time_local || new Date().toTimeString()) >
//           Date.parse(b.booking_details?.start_time_local || new Date().toTimeString())
//         ? -1
//         : 0;
//     })[0];
//   }
// };

const ScheduleWidget = observer((props: ScheduleWidgetProps) => {
  const schedule = props.schedule!;
  const { t, i18n } = useTranslation();
  const momentDate: moment.Moment = moment(schedule.date);
  const date = momentDate.toDate();
  const originalStatus = schedule.status;
  const isWeekend = [0, 6].indexOf(date.getDay()) !== -1;
  const isPast = dateInPastArrow(momentDate);
  const today = isToday(date);
  const tooFarAhead = isDayTooFarToBook(date);
  const rootStore = useRootStore();
  const scheduleStore = rootStore.scheduleStore;
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  const history = useHistory();
  const allHolidays = useContext(HolidayContext);

  let desk_not_required = false;
  if (schedule && "desk_required" in schedule) {
    if (schedule.desk_required == 2) {
      desk_not_required = true;
    }
  }
  const styles = {
    eventSeat: {
      fontSize: "12px",
    },
  };

  const change = (scheduleType: ScheduleType): (() => void) => {
    return async () => {
      if (
        props.showBooking &&
        originalStatus === ScheduleType.OFFICE &&
        !schedule.space &&
        scheduleType === ScheduleType.OFFICE &&
        !tooFarAhead &&
        schedule.office?.building_type === BuildingType.OFFICE
      ) {
        history.push(`/booking/${moment(date).format("YYYY-MM-DD")}/${schedule.office.id}`);
      }
      if (scheduleType === originalStatus) {
        return;
      }
      if (originalStatus === ScheduleType.OFFICE && schedule.space) {
        // console.log(JSON.stringify(props.upcomingBookings));
        // we want to load a confirm status
        scheduleStore.scheduleChangingAwayFromOfficeWithBooking = {
          schedule: schedule,
          status: scheduleType,
          bookings: (props.upcomingBookings || []).filter(
            (booking) =>
              booking.booking_details?.start_date_local === schedule.date ||
              booking.booking_details?.end_date_local === schedule.date
          ),
        };
      } else {
        try {
          await scheduleStore.changeSchedule(schedule.date, scheduleType);
        } catch (err) {
          rootStore.setError(err as AxiosError);
        }
      }
    };
  };

  const getHolidaysForSchedule = () => {
    const holidaysFound =
      allHolidays.findIndex((holiday) => holiday.date === schedule.date) >= 0 ? true : false;
    const scheduleDayHolidays = holidaysFound
      ? allHolidays.find((holiday) => holiday.date === schedule.date)?.holidays
      : [];
    return scheduleDayHolidays && scheduleDayHolidays.length > 0 ? scheduleDayHolidays : null;
  };

  return (
    <Box
      display="flex"
      position="relative"
      flexDirection="column"
      borderRadius={2}
      flexGrow={1}
      flexBasis={0}
      flexShrink={0}
      maxWidth={smAndUp ? 150 : "initial"}
      mx={0.5}
      py={1}
      px={1}
      style={{ overflow: "hidden", width: 0 }}
      border={today ? `1px solid ${bhpColor.blueGrey1}` : "none"}
      bgcolor={isWeekend ? "white" : bhpColor.backgroundGrey}
      justifyContent="center"
      className={isPast ? "dimmed" : ""}
    >
      {schedule.updating && <LoadingZone isLoading={schedule.updating} />}
      {schedule.updating}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          fontSize={13}
          style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
          color={bhpColor.blueGrey1}
          fontWeight="fontWeightBold"
          justifyContent="flex-start"
        >
          {mdAndUp
            ? `${t(moment(date).format("Do"))} ${t(moment(date).format("MMMM"))}`
            : `${t(moment(date).format("Do"))} ${t(moment(date).format("MMM"))}`}
        </Box>
        {desk_not_required && (
          <Tooltip title="No desk required">
            <img src={no_desk_image} height="18px" />
          </Tooltip>
        )}

        <Box justifyContent="flex-start">
          {mdAndUp && (
            <HolidayPopup holidays={getHolidaysForSchedule()} date={schedule.date} t={t} />
          )}
        </Box>
        {!smAndUp && (
          <Box
            fontSize={12}
            style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
            color={bhpColor.blueGrey1}
            justifyContent="flex-end"
            display="inline-flex"
          >
            {schedule?.start_time && schedule?.finish_time ? (
              <>
                <Box px={0.5}>
                  <Icon path={mdiClockOutline} size={"15px"} />
                </Box>
                <Box>
                  {moment(schedule?.start_time, ["HH:mm", "H:mm"]).format("hh:mm A")} -{" "}
                  {moment(schedule.finish_time, ["HH:mm", "H:mm"]).format("hh:mm A")}
                </Box>
              </>
            ) : (
              ""
            )}
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        marginBottom={smAndUp ? "initial" : 2}
        flexDirection={smAndUp ? "column" : "row"}
        justifyContent="space-between"
      >
        <OfficeButton
          onClick={change(ScheduleType.OFFICE)}
          showBooking={props.showBooking}
          schedule={schedule}
          tooFarAhead={tooFarAhead}
          selected={schedule.status === ScheduleType.OFFICE}
          earliestBooking={
            props.upcomingBookings
              ? getEarliestBooking(props.upcomingBookings, schedule.date)
              : null
          }
          desk_not_required={desk_not_required}
          showLoading={props.showLoading}
          displayMessage={props.displayMessage}
          isOpen={props.isOpen}
          isApiError={props.isApiError}
        />
        <RemoteButton
          onClick={change(ScheduleType.REMOTE)}
          selected={schedule.status === ScheduleType.REMOTE}
        />
        <OffButton
          onClick={change(ScheduleType.OFF)}
          selected={schedule.status === ScheduleType.OFF}
        />

        {smAndUp && props.showBooking && (
          <Fragment>
            <DisplayHours
              startTime={schedule?.start_time}
              finishTime={schedule?.finish_time}
              scheduleType={schedule.status}
            />
            <Button
              size="small"
              disableElevation
              variant="contained"
              onClick={() => {
                props.handleEditHoursLocation(schedule);
              }}
              className={classes.editHoursLocationButton}
              disabled={schedule.status === ScheduleType.OFF}
            >
              {t("Edit Hours/Location")}
            </Button>
          </Fragment>
        )}
      </Box>
      {!smAndUp && (
        <Box mx={-8}>
          {!smAndUp && (
            <Button
              size="small"
              disableElevation
              variant="contained"
              onClick={() => {
                props.handleEditHoursLocation(schedule);
              }}
              className={classes.editHoursLocationButton}
              style={{
                marginTop: 0,
                backgroundColor: `${isWeekend ? bhpColor.blueGrey3 : bhpColor.blueGrey4}`,
                color: `${isWeekend ? bhpColor.white : bhpColor.blueGrey1}`,
              }}
              disabled={schedule.status === ScheduleType.OFF}
            >
              {t("Edit Hours/Location")}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
});
export default ScheduleWidget;
